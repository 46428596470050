@use "@angular/material" as mat;

@include mat.core();

@import "./assets/styles/variables.scss";
@import "./assets/styles/daitask-theme.scss";
@import "./assets/styles/daitask-child-theme.scss";

@include mat.all-component-themes($daitask-theme);

:root {
  --font-family-kids: "Comic Neue", cursive;
  --font-family-adults: "Roboto", sans-serif;

  --adult-h1: 20px;
  --adult-h1-subtitle: 15px;
  --adult-h2: 20px;
  --adult-body-title: 16px;
  --adult-body: 16px;
  --adult-body-secondary: 14px;
  --adult-menuelement-title: 20px;
  --adult-menu-text: 13px;
  --adult-btn-text: 15px;

  --weight-bold: 700;
  --weight-regular: 400;

  --kids-h1: 21px;
  --kids-h1-subtitle: 18px;
  --kids-h2: 21px;
  --kids-body: 18px;
  --kids-body-secondary: 14px;

  --adult-color-green: #e1ebe5;
  --adult-color-beige: #efebe4;
  --adult-color-white: #fff;
  --adult-color-black: #242424;
  --adult-color-blue: #628395;
}

/* --------------------------------------------------- C S S   R E S E T ------------------------------------------------*/
// 1. Use a more-intuitive box-sizing model.
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

// 2. Remove default margin
* {
  margin: 0;
}

// Typographic tweaks! 3. Add accessible line-height 4. Improve text rendering
body {
  line-height: 1.5;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
}

// 5. Improve media defaults
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

// 6. Remove built-in form typography styles
input,
button,
textarea,
select {
  font: inherit;
}

input,
select,
textarea {
  font-size: 1rem !important;
  /* Browsers that don't understand the following max() will use this */
  // font-size: max(1rem, 16px) !important;
  /* Lucky for us, Safari iOS understands max() */
}

// changing the blinking cursor color
input {
  caret-color: #242424 !important;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

// Disable blue highlight on button click
button {
  -webkit-tap-highlight-color: transparent !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  color: #242424 !important;
  border: none;
  background-color: transparent;
  background: transparent;
  outline: none;
}

button:focus {
  -webkit-tap-highlight-color: transparent !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

.-mdc-focus-indicator {
  -webkit-tap-highlight-color: transparent !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

button:disabled,
button[disabled] {
  opacity: 0.6;
}

// 7. Avoid text overflows
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
  margin: 0;
  padding: 0;
}

p {
  line-height: 1.6;
}

html,
body {
  height: 100%;
}

html,
body,
app-root .large-screen-background {
  background-color: #fff;
  padding: 0;
  margin: 0;
}

/* ---------------------------------------- C S S   R E S E T    E N D ---------------------------------------------------*/

.mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #f7f7f7 !important;
  --mdc-chip-elevated-disabled-container-color: none;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  border-style: solid;
  border-width: 2px;
  border-color: #e1ebe5;
}
.mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected,
.mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #e1ebe5;
  --mdc-chip-elevated-disabled-container-color: #e1ebe5;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}

.mat-icon-button {
  background-color: transparent;
}

.mat-icon-button:hover {
  background-color: transparent;
}

.mat-icon-button:focus {
  background-color: transparent;
}

.mat-icon-button:active {
  background-color: transparent;
}

.smart-task-dialog {
  border-radius: 30px !important;
  --mdc-dialog-container-shape: 20px;
}

.smart-task-dialog .mat-mdc-dialog-container {
  border-radius: 30px !important;
  --mdc-dialog-container-shape: 20px;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-shape: 0px;
}

// ::ng-deep .comic {
//   font-family: "Comic Neue", cursive;
// }

mat-error,
mat-hint {
  font-size: 12px;
}

mat-form-field {
  width: 100%;
}

.mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
  color: rgba(0, 0, 0, 0.6) !important;
}

.mdc-notched-outline__leading {
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}

.mdc-notched-outline__trailing {
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
  .mdc-notched-outline__notch {
  border-left: 0px !important;
}

/* Change the border color */
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.2) !important;
}

/* Change the border width */
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline
  .mdc-notched-outline__trailing {
  border-width: 2px !important;
}

.mat-mdc-icon-button.mat-primary {
  color: #504139;
}

.additional-selection {
  opacity: 0.75;
  font-size: 0.75em;
}

.daitask-theme {
  --color-primary: #{mat.m2-get-color-from-palette($daitask-primary)};
  --color-accent: #{mat.m2-get-color-from-palette($daitask-accent)};
  --color-warn: #{mat.m2-get-color-from-palette($daitask-warn)};
  --color-danger: #{mat.m2-get-color-from-palette($daitask-warn)};
}

.daitask-child-theme {
  // include the DaiTask theme into the angular material theme
  @include mat.all-component-colors($daitask-child-theme);
  @include mat.all-component-typographies($daitask-child-theme);
  --color-primary: #{mat.m2-get-color-from-palette($daitask-child-primary)};
  --color-accent: #{mat.m2-get-color-from-palette($daitask-child-accent)};
}

.mat-typography .daitask-child-theme,
.mat-typography .daitask-child-theme h2 {
  font-family: "Comic Neue", cursive;
}

.danger-container {
  color: var(--color-danger);
  background: color-mix(in srgb, currentColor 5%, transparent);
  padding: 20px;
  border-radius: 4px;
}

.warning-container {
  color: var(--color-warn);
  background: color-mix(in srgb, currentColor 10%, transparent);
  padding: 20px;
  border-radius: 4px;
}

app-child-timer {
  display: flex;
  justify-content: center;
}

// drag and drop
.custom-drag-placeholder {
  background: #ccc;
  border: dotted 3px #999;
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.density-form-field-2 {
  @include mat.form-field-density(-2);
}

.density-form-field-3 {
  @include mat.form-field-density(-3);
}

// ------------ Material Draggable task styling -------------
.draggable-task {
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: move;
  background: white;
}

.draggable-task.parent {
  padding: 8px 10px;
  margin: 8px auto;
}

.draggable-task-list {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px !important;
  background: white;
  padding: 15px;
  margin-top: 24px;
  width: 100%;
  max-width: 100%;
  display: block;
}

.draggable-publishtask-list {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px !important;
  background: white;
  padding: 15px;
  margin-left: 4px;
  width: calc(
    100% - 8px
  ); /* Accounts for left and right margins of 16px each */
  display: block;
  margin-top: 24px;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

// Don't delete this :)
// .draggable-sub-tasks {
//   border-bottom: 1px solid silver;
// }

.draggable-task-list.cdk-drop-list-dragging
  .draggable-task:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.mat-mdc-card .mdc-card .timetablebg {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.mat-mdc-card {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

//fixer icon højde
.mat-icon {
  height: auto !important;
}

// runde checkbokse på voksen dashboard
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  border-radius: 6px !important;
}

// for displaying icon in time input
input.mat-mdc-input-element::-webkit-calendar-picker-indicator {
  display: block;
}

// Fjerner padding på voksen dashboard
.mdc-list-item {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

// selected options in drop-down list
.mat-pseudo-checkbox-checked {
  background-color: #504139 !important;
  margin-left: 10px;
}

// selected options in drop-down list
.mat-pseudo-checkbox {
  margin-left: 10px;
}

// .mat-primary
//   .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
//   .mdc-list-item__primary-text {
//   color: #242424 !important;
// }

// .mdc-list-item--selected .mdc-list-item__primary-text,
// .mdc-list-item--activated .mdc-list-item__primary-text {
//   color: #504139 !important;
// }

.mat-mdc-outlined-button {
  border-radius: 15px;
  width: 100%;
  text-align: left;
}

.mdc-button .mdc-button__label {
  width: 100% !important;
  font-size: 14px;
}

.mat-expansion-panel {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.mat-line {
  font-size: 13px;
}

.new-draggable-task {
  background-color: white;
}

.mat-snack-bar-container {
  margin-top: 50px !important;
}

.no-disabled-color {
  color: rgba(0, 0, 0, 0.87) !important;
}

.notifications-warning-text {
  font-size: medium;
  display: flex;
  align-items: center;
  color: white;
  background: var(--color-warn);
  padding-left: 15px;
  border-radius: 20px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.notifications-warning-text mat-label {
  padding-left: 10px;
  font-family: "Roboto", sans-serif;
}

.notifications-warning-text fa-icon {
  font-size: large;
  color: white;
  background: var(--color-warn);
}

// .mat-mdc-card,
// .draggable-task-list {
// }

.subscription-btn {
  padding: 12px 24px;
  border-radius: 20px;
  color: #242424 !important;
  background-color: #e1ebe5;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-size: 15px;
  margin-bottom: 8px;
}

.mat-mdc-form-field-focus-overlay {
  opacity: 1 !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: rgba(255, 255, 255, 1);
}

.mat-tab-header.mat-mdc-tab-header {
  border-radius: 20px !important;
}

// .time-container {
//   display: flex;
//   flex-direction: row;
//   width: 100%;
//   margin-bottom: 12px;
// }

// .time-container .margin-left {
//   margin-left: 4px;
// }

// .time-container .right-align {
//   text-align: right;
// }

// .time-container .time {
//   width: 200px;
//   height: fit-content;
// }

/* ------------------------ Our own style components ---------------------*/
//fam-card replacing mat-card over time
.fam-card {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: var(--adult-color-white);
  padding: 20px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
}

.text-body {
  font-size: var(--adult-body);
  line-height: 1.5;
  color: var(--adult-color-black);
}

.page-wrapper {
  margin-left: 24px;
  margin-right: 24px;
  max-width: 100%;
  margin-bottom: 32px;
}

.page-wrapper-no-sidemargin {
  margin-top: 32px;
  max-width: 100%;
  margin-bottom: 150px;
  position: relative;
}

.sheet-wrapper {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 32px;
  max-width: 100%;
  padding-bottom: calc(32px + env(safe-area-inset-bottom));
}

.dialog-wrapper {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: calc(100px + env(safe-area-inset-top));
  max-width: 100%;
  margin-bottom: 32px;
  // overflow-x: clip;
  // overflow-y: clip;
  height: fit-content;
}

// ------------------------------------------ M A T E R I A L    S T Y L I NG ----------------------------------------------
/* -------------------------------------- Material dialogs ------------------------------------*/
// The calender dialog box
.calendar-dialog .mat-mdc-dialog-container {
  overflow-y: hidden;
  border-radius: 20px;
}

.kid-calendar-dialog .mat-mdc-dialog-container {
  overflow-y: hidden;
  border-radius: 20px;
  background-color: #00425a;
}

// Halloween stuff
.halloween-wheel-dialog .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  background-image: url("../src/assets/images/halloween-wheel/wheel-background.png") !important;
  background-size: cover;
  background-position: center top;
}

.halloween-dialog-early .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  background-color: transparent !important;
  box-shadow: none !important;
}

.halloween-activity-dialog .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  background-color: #fff;
}

.app-won-summer-dialog .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  background: rgb(12, 192, 223);
  // background: linear-gradient(90deg, rgba(12, 192, 223, 1) 0%, rgba(255, 222, 89, 1) 100%);
  background: linear-gradient(
    90deg,
    rgba(255, 149, 0, 1) 0%,
    rgba(255, 222, 89, 1) 100%
  );

  border-radius: 15px;
}

.app-won-halloween-dialog .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  background: linear-gradient(
    90deg,
    rgba(255, 149, 0, 1) 0%,
    rgba(255, 222, 89, 1) 100%
  );
  border-radius: 15px;
}

.kid-countdown-dialog .mat-mdc-dialog-container {
  overflow-y: hidden;
  border-radius: 20px;
}

.kid-reward-dialog .mat-mdc-dialog-container {
  overflow-y: hidden;
  border-radius: 0;
}
.delete-dialog .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  border-radius: 15px;
}

// .view-timetable-dialog .mat-mdc-dialog-container .mat-mdc-dialog-surface {
//   background-image: url("../src/assets/images/timetable/timetable-background.png") !important;
//   background-size: cover;
//   background-position: center top;
//   position: relative;
//   z-index: 9999999 !important;
// }

.app-full-bleed-dialog .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  background-color: #fff;
}

.app-full-bleed-dialog2 .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  background-image: url("../src/assets/images/summer-wheel/summerwheel-bg.png") !important;
  background-size: cover;
  background-position: center top;
}

.app-won-summer-dialog .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  background: rgb(12, 192, 223);
  background: linear-gradient(
    90deg,
    rgba(12, 192, 223, 1) 0%,
    rgba(255, 222, 89, 1) 100%
  );
  border-radius: 15px;
}

.app-won-halloween-dialog .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  background: #fff;
  border-radius: 15px;
}

// The calender dialog box
.calendar-dialog .mat-mdc-dialog-container {
  overflow-y: hidden;
  border-radius: 20px;
}

.kid-calendar-dialog .mat-mdc-dialog-container {
  overflow-y: hidden;
  border-radius: 20px;
  background-color: #00425a;
}

.kid-countdown-dialog .mat-mdc-dialog-container {
  overflow-y: hidden;
  border-radius: 20px;
}

.kid-reward-dialog .mat-mdc-dialog-container {
  overflow-y: hidden;
  border-radius: 0;
}

// The Succes Message dialog box
.succes-dialog .mat-mdc-dialog-container {
  border-radius: 15px;
  background-color: #242424;
}

/* -------------------- Different material dialogs ------------------------*/
.delete-dialog .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  border-radius: 15px;
}

.view-timetable-dialog .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  background-image: url("../src/assets/images/timetable/timetable-background.png") !important;
  background-size: cover;
  background-position: center top;
  position: relative;
  z-index: 9999999 !important;
}

.app-full-bleed-dialog .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  background-color: #fff;
}

.app-full-bleed-dialog2 .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  background-image: url("../src/assets/images/summer-wheel/summerwheel-bg.png") !important;
  background-size: cover;
  background-position: center top;
}

.halloween-dialog .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  background-image: url("../src/assets/images/halloween-wheel/background.png") !important;
  // background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  // background-position: center top;
}

// ----------------------------- Material Bottom Sheets ------------------------------
.mat-bottom-sheet-container {
  padding: 0px 0px 0px 0px !important;
  border-radius: 15px 15px 0px 0px;
}

.mat-bottom-sheet-container .bottom-sheet-title span {
  margin: auto;
  width: fit-content;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 250px;
}

.mat-mdc-option:focus.mdc-list-item,
.mat-mdc-option.mat-mdc-option-active.mdc-list-item {
  // This one sets the selected/active item color on the list!
  --mat-option-selected-state-label-text-color: #242424 !important;
  color: #242424 !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text {
  --mat-option-selected-state-label-text-color: #242424 !important;
}

.mat-mdc-option.mdc-list-item {
  padding: 5px !important;
}

// Manipulate initial background color
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white !important;
}

// Manipulate background color on hover and focus
.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

// .custom-field
//   .mat-mdc-form-field-flex
//   .mdc-notched-outline
//   .mdc-notched-outline__trailing {
//   border-radius: 25px;
// }

.mdc-text-field--filled {
  border-radius: 20px !important;
  border: 1px solid rgb(168, 168, 168) !important;
}

//=================================================
// The select panel dropdown styling
//=================================================
